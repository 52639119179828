<template>
  <div class="public">
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right" style='margin-bottom:20px'>
      <el-breadcrumb-item >资源库管理</el-breadcrumb-item>
      <el-breadcrumb-item to='/zykgl/public'>公告管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{(edit?'编辑':'新增')+'公告'}}</el-breadcrumb-item>
    </el-breadcrumb>-->
    <el-form
	class=""
      :rules="dataRules"
      label-width="140px"
      :model="form"
      ref="form"
      v-loading="dataListLoading"
    >

      <el-form-item label="AI测评模型名称" >
		{{form.modelName}}
      </el-form-item>
	  <el-form-item v-if="!edit" label="模型编号" >
		  {{form.modelNumber}}
	  </el-form-item>
    <el-form-item  label="采集数据类型" >
		<span v-if="form.collectType==1">应激反应数据</span>
		<span v-if="form.collectType==2">舌苔图片数据</span>

    </el-form-item>
     <el-form-item  v-if="form.collectType==1" label="刺激源形式">
		 <div v-if="checkList.includes('视频观看')">视频观看</div>
		 <video controls  v-if="checkList.includes('视频观看')&&fileList.length>0" :src="fileList[0].url"></video>
		  <div v-if="checkList.includes('文字朗读')">文字朗读</div>
		<div>{{txtContent}}</div>
     </el-form-item>

  <el-form-item label="状态">
	  <div v-if="form.status==1">启用</div>
	  <div v-else>禁用</div>
     
  </el-form-item>

   
    </el-form>
  </div>
</template>
<script>
import TinyMce from "@/components/tiny-mce";
import Qs from "qs";
import moment from "moment";
export default {
  name: "publicAdd",
  components: {
    TinyMce,
  },
  data() {
    return {
      edit: false,
	  actionUrl:'',
	  importHeader: {
	    token: ""
	  },
	  fileList: [],
	    fileList2: [],
	  checkList: [],
	  txtContent:'',
      form: {
       modelNumber	: "",

       modelName		: "",

       diseaseType		: "",

       stimulusForm		: "",

       status	: 1,
      },
      diseasesList: [],
      typeList: [],
      dataRules: {
       modelName: [
         { required: true, message: "AI测评模型名称不能为空", trigger: "blur" },
       ],
	   modelNumber: [
	     { required: true, message: "模型编号不能为空", trigger: "blur" },
	   ],
        diseaseType: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],

      },
      dataListLoading: false,
      type: "",
    };
  },
  async mounted() {
       this.importHeader.token = localStorage.token;
	   this.actionUrl =  this.$global.baseURL + "/aliyun/oss/upload";
    this.form.publishTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let query = this.$route.query;
    if (query.id) {
      this.edit = true;
      this.dataListLoading = true;
      await this.init(query.id);
    }
  
  },
  methods: {
	  setCheck(val){
		

		
	  },
	     handleSuccess2(response, file, fileList) {
			 let obj = {
			 			   name:file.name,
			 			   url:response.result.filePath
			 }
			 this.fileList2 = [obj]
		 },
		 handleSuccess(response, file, fileList) {
		   console.log(response, "response");
		   let obj = {
			   name:file.name,
			   url:response.result.filePath
		   }
		   this.fileList = [obj]
		   
		 },
		 beforeUpload(file,fileType) {
		   let fileName = file.name;
		   let pos = fileName.lastIndexOf(".");
		   let lastName = fileName.substring(pos, fileName.length);
		   let type = lastName.toLowerCase();
		   if (fileType == "1") {
	
		     if (
		       type != ".mp4" 
		     
		     ) {
		       this.$message.error(
		         "上传文件只能是.mp4 格式!"
		       );
		       this.fileList = [];
		       return false;
		     }
		     return true;
		   } else if (fileType == "2") {
		     // let isLt2M = file.size / 1024 / 1024 < 50;
		     if (type != ".txt" ) {
		       this.$message.error("上传文件只能是.txt格式!");
		       this.fileList2 = [];
		       return false;
		     }
		 
		     return true;
		   } 
		 },
    back() {
      this.$router.back();
    },
    async init(id) {

	  // if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
	  const { data: res } = await this.$httpAes({
	    url: this.$httpAes.adornUrl("/diagnosisModel/info"),
	    method: "post",
	    data: { stringParam1: id },
	  		contentType:'json',
	  		headers: {
	  			'Content-Type': 'application/json',
	  		   				
	  		},
	  });
	  if (res.status) {
	    this.form = res.data;

		this.checkList = this.form.stimulusForm.split(',')
		  this.listPic(id)
		this.form.diseaseType = parseInt(this.form.diseaseType)
	    this.dataListLoading = false;
	  }
	
    },
    async getTypeList() {
      let params = {
        stringParam1: "病种",
      };
      // if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list"),
        method: "post",
        data: params,
		contentType:'json',
		headers: {
			'Content-Type': 'application/json',
		   				
		},
      });
      // console.log(res,'res')
      this.typeList = res.data;
	  let obj = this.typeList.find(this.findType)
	    console.log('findfindfindfindfindfind')
	  console.log(obj)
	 this.form.diseaseTypeName = obj.name
    },
	findType(item){
		return item.value == this.form.diseaseType
	},
	async listPic(id) {
		var that = this
		this.txtContent = ''
		if(this.checkList.includes('视频观看')){
			let params = {
			    stringParam1:id,
				intParam1:118
			  };
			  // if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
			  const { data: res } = await this.$httpAes({
			    url: this.$httpAes.adornUrl("/sysPic/listPic"),
			    method: "post",
			    data: params,
				contentType:'json',
				headers: {
					'Content-Type': 'application/json',
				   				
				},
			  });
			this.fileList = JSON.parse(res.data[0].picUrl) 
		}
	 if(this.checkList.includes('文字朗读')){
	 	let params = {
	 	    stringParam1:id,
	 		intParam1:119
	 	  };
	 	  // if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
	 	  const { data: res } = await this.$httpAes({
	 	    url: this.$httpAes.adornUrl("/sysPic/listPic"),
	 	    method: "post",
	 	    data: params,
	 		contentType:'json',
	 		headers: {
	 			'Content-Type': 'application/json',
	 		   				
	 		},
	 	  });
	 	this.fileList2 = JSON.parse(res.data[0].picUrl) 
		let request = new XMLHttpRequest();
		request.open("GET", this.fileList2[0].url, true);
		request.onload = function() {
		if (this.status >= 200 && this.status < 400) {
		let data = this.responseText;
			console.log('responseTextresponseTextresponseTextresponseTextresponseTextresponseTextresponseTextresponseTextresponseTextresponseTextresponseTextresponseTextresponseText');
		console.log(data);
		that.txtContent = data
		} else {
		console.error("获取文件内容失败");
		}
		};
		request.onerror = function() {
		console.error("网络连接异常");
		};
		request.send();
	 }
	},
	async insertPic(actionId) {
	
	  let params = {
	    stringParam1: []
		
	  }

	  if(this.checkList.includes('视频观看')){
		  let obj = {
	  	actionId: actionId,
	  	picUrl: JSON.stringify(this.fileList),
	  	picType: 118,
	  }
	  	params.stringParam1.push(obj)
	  }
	  if(this.checkList.includes('文字朗读')){
	  	let obj = {
	  		actionId: actionId,
	  		picUrl: JSON.stringify(this.fileList2),
	  		picType: 119,
	  	}
	  		params.stringParam1.push(obj)
	  }
	  // if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
	  const { data: res } = await this.$httpAes({
	    url: this.$httpAes.adornUrl("/sysPic/insertMultiHousePic"),
	    method: "post",
	    data: params,
		contentType:'json',
		headers: {
			'Content-Type': 'application/json',
		   				
		},
	  });
	   console.log(res,'res')
 if (res.status) {
          this.$router.go(-1)
          }
	},
	async deleteHousePic(id) {
	  let params = {
	    stringParam1:id,
	  };
	  // if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
	  const { data: res } = await this.$httpAes({
	    url: this.$httpAes.adornUrl("/sysPic/deleteHousePic"),
	    method: "post",
	    data: params,
		contentType:'json',
		headers: {
			'Content-Type': 'application/json',
		   				
		},
	  });
	
	},

    async submit() {
if(this.checkList.includes('视频观看')&&this.fileList.length==0){
	this.$message.error('请上传视频文件')
	return
}
if(this.checkList.includes('文字朗读')&&this.fileList2.length==0){
	this.$message.error('请上传文本文件')
	return
}
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let form = this.form;
		form.stimulusForm = this.checkList.join(',')

          const { data: res } = await this.$httpAes({
            url: this.$httpAes.adornUrl(
              form.id ? "/diagnosisModel/update" : "/diagnosisModel/add"
            ),
            method: "post",
			contentType:'json',
			headers: {
				'Content-Type': 'application/json',
			   				
			},
            data: this.form,
          });
          console.log(res);
          if (res.status) {
            this.$message.success(res.msg);
			if(form.id){
			await this.deleteHousePic(res.data.id)
			await this.insertPic(res.data.id)
			}else{
				this.insertPic(res.data.id)
			}
            
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.public {
}
</style>